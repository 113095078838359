import React, { Component } from 'react';

import styles from './styles.module.scss';

class Story extends Component {
  render() {
    const { story } = this.props;

    return (
      <div className={styles.story}>
        <h2 className={styles.title}>{story.title}</h2>
        <p className={styles.description}>{story.description}</p>
        <div className={styles.iframeWrapper}>
          <iframe
            src={story.url}
            allowFullScreen
            scrolling="no"
            allow="encrypted-media"
            title={story.title}
            className={styles.embed}
          >
          </iframe>
        </div>
      </div>
    );
  }
}

export default Story;
