import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import About from './containers/About';
import Contact from './containers/Contact';
import Resume from './containers/Resume';
import Work from './containers/Work';

import Navbar from './components/Navbar';

import './styles/main.scss';

function App() {
  return (
    <Router>
      <div className='appContainer'>
        <Navbar />
        <Switch>
          <Redirect exact from="/" to="portfolio" />
          <Route path="/portfolio">
            <Work />
          </Route>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
