import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.heading}>
        <h1 className={styles.header}>Tyre Jones</h1>
        <h3 className={styles.subheader}>Story Artist</h3>
      </div>
      <ul className={styles.list}>
        <li className={styles.link}>
          <NavLink to="/portfolio" activeClassName={styles.active}>Portfolio</NavLink>
        </li>
        <li className={styles.link}>
          <NavLink to="/resume" activeClassName={styles.active}>Resume</NavLink>
        </li>
        <li className={styles.link}>
          <NavLink to="/about" activeClassName={styles.active}>About</NavLink>
        </li>
        <li className={styles.link}>
          <NavLink to="/contact" activeClassName={styles.active}>Contact</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
