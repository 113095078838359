import React from 'react';

import styles from './styles.module.scss';

import logoIg from './ig.jpg';

const Contact = () => {
  return (
    <div className={styles.contact}>
      <div className={styles.email}>
        tyrejonesr@gmail.com
      </div>
      <a href="https://www.instagram.com/tyre.jones/" target="_blank" rel="noopener noreferrer">
        <img src={logoIg} alt="instagram" className={styles.logo} />
      </a>
    </div>
  );
}

export default Contact;
