import React from 'react';

import styles from './styles.module.scss';

import aboutImg from './tyre-about.JPG';

const About = () => {
  return (
    <div className={styles.about}>
      <img src={aboutImg} alt='Tyre' className={styles.img} />
      <div className={styles.description}>Tyre Jones is a Story Artist from Long Beach, CA. He graduated from CSULB in 2016 with a degree in creative writing and watches too much basketball in his spare time.</div>
    </div>
  );
}

export default About;
