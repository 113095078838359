import React from 'react';

import Story from '../../components/Story';

const STORIES = [
  {
    title: 'A for Effort (2019)',
    description: 'A personal project about a vengeful kid with poor execution skills.',
    url: 'https://speakerdeck.com/player/4ac405cc9aee43fd8062efb9e41373a5'
  },
  {
    title: 'The Food Chain (2017)',
    description: 'A class assignment I was given where I was to create a sequence about two characters meeting for the first time.',
    url: 'https://speakerdeck.com/player/3763e0cb50534fd78edfaa1d358f742b'
  },
  {
    title: 'Grandpops (2017)',
    description: 'A sequence about a character trying to connect with another.',
    url: 'https://speakerdeck.com/player/04ce826af1f14a2aa86bfe0eef62d55a'
  }
]

const Work = () => {
  return (
    <div>
      {
        STORIES.map((story, index) => (
          <Story story={story} key={index}/>
        ))
      }
    </div>
  );
}

export default Work;
