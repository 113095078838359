import React from 'react';

import styles from './styles.module.scss';

const RESUME = [
  {
    title: 'Experience',
    entries: [
      {
        title: 'Moon Girl and Devil Dinosaur – Disney TVA',
        subtitle: 'Storyboard Artist',
        date: 'May 2020 - present'
      },
      {
        title: 'Craig of the Creek – Cartoon Network Studios',
        subtitle: 'Freelance Storyboard Revisionist',
        date: 'October 2019 - November 2019'
      },
      {
        title: 'The Rocketeer – Wild Canary Animation',
        subtitle: 'Storyboard Revisionist',
        date: 'August 2018 - October 2019'
      },
      {
        title: 'Danger Mouse – Boulder Media',
        subtitle: 'Freelance Storyboard Artist',
        date: 'December 2017 - June 2018'
      }
    ]
  },
  {
    title: 'Education',
    entries: [
      {
        title: 'California State University, Long Beach',
        subtitle: 'BA in English – Creative Writing',
        date: 'Class of 2016'
      },
      {
        title: 'Concept Design Academy',
        subtitle: 'Story Development for Animation, Tron Mai',
        another: 'Cinematic Storyboarding, Ben Junwono',
        date: 'Summer 2016 – Summer 2017'
      },
      {
        title: 'Rad Sechrist – How to School',
        subtitle: 'Storyboarding for Feature Animation, Rad Sechrist',
        date: 'Winter 2016'
      }
    ]
  },
  {
    title: 'Additional Experience',
    entries: [
      {
        title: 'Motivarti Mentorship Program',
        subtitle: 'Storyboarding Mentor – Ian Abando',
        date: 'Spring 2016'
      }
    ]
  }
]

const Entry = ({ entry }) => {
  return (
    <div className={styles.entry}>
      <div>
        <div>{entry.title}</div>
        <div className={styles.subtitle}>{entry.subtitle}</div>
        {entry.another &&
          <div className={styles.subtitle}>{entry.another}</div>
        }
      </div>
      <div className={styles.date}>{entry.date}</div>
    </div>
  )
}

const Section = ({ section }) => {
  return (
    <div className={styles.section}>
      <span className={styles.sectionTitle}>{section.title}</span>
      <div className={styles.entries}>
        {section.entries.map(entry => (
            <Entry entry={entry} key={entry.title} />
          ))
        }
      </div>
    </div>
  )
}

const Resume = () => {
  return (
    <div className={styles.resume}>
      {RESUME.map(section => (
        <Section section={section} key={section.title} />
      ))}
      <div className={styles.section}>
        <span className={styles.sectionTitle}>Skills</span>
        <div className={styles.entry}>
          <div className={styles.skills}>Adobe Photoshop, Storyboard Pro, Wacom Cintiq</div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
